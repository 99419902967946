<template>
  <div class="wrap" v-loading="false" id="gunlun">
    <div class="mine_info">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="status" label="缴纳状态" width="200">
          <template slot-scope="scope">
            <el-tag
              :type="scope.row.status === 1 ? '' : 'danger'"
              effect="plain"
              style="border: 0"
            >
              {{ scope.row.status === 1 ? "已缴纳" : "为缴纳" }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="姓名" width="200">
        </el-table-column>
        <el-table-column prop="address" label="时间" width="480">
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button size="mini">{{
              scope.row.status === 1 ? "取消" : "缴纳"
            }}</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  //组件引入
  components: {},
  //状态存储
  data() {
    return {
      loading: false,
      tableData: [
        {
          status: 1,
          date: "2016-05-02",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          status: 2,
          date: "2016-05-02",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
      ],
    };
  },
  //计算属性
  computed: {},
  //状态监听
  watch: {},
  //存放方法集合
  methods: {
    //   获取列表数据
    list() {},
  },
  //数据初始化之后
  created() {
    this.list();
  },
  //页面渲染之后
  mounted() {},
  //数据初始化之前
  beforeCreate() {},
  //页面渲染之前
  beforeMount() {},
  //状态更新之前
  beforeUpdate() {},
  //状态更新之后
  updated() {},
  //页面卸载之前
  beforeDestroy() {},
  //卸载之后
  destroyed() {},
  //页面进入   页面缓存时有效
  activated() {},
};
</script>
<style  scoped lang='scss'>
/* //@import url(); 引入公共css类 */
.fenye {
  margin-top: 20px;
  display: flex;
  float: right;
}
.wrap {
  @include padding;
  min-width: 1100px;
  min-height: 800px;
}
.mine_info {
  max-width: 1200px;
  min-width: 900px;
  // min-height: 500px;
  padding: 20px;
  background-color: #fff;
  /* display: flex;
  align-items: center; */

  border-radius: $radius;
}
.demo-table-expand {
  font-size: 0;
}
.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}
</style>